import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import WebManagementIllustration from "../../../svg/ComparisonResultPageIllustrations/webdomainmanagement.svg";
import DomainComSVG from "../../../svg/ComparisonResultGridLogos/webdomainmanagement/domaincom.svg";
import NameCheapSVG from "../../../svg/ComparisonResultGridLogos/webdomainmanagement/namecheap.svg";
import OneAndOneSVG from "../../../svg/ComparisonResultGridLogos/webdomainmanagement/oneandone.svg";
import GoDaddySVG from "../../../svg/ComparisonResultGridLogos/webdomainmanagement/godaddy.svg";
import NetworkSolutionsSVG from "../../../svg/ComparisonResultGridLogos/webdomainmanagement/networksolutions.svg";
export const pageMetaData = {
  pageTitle: "Compare Web Domain Management Services",
  pageDescription: "Manage Your Domain From $1",
  pageImagePath: "/webdomainmanagement.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "1st Year Price (TLD .com)"
      }, {
        index: 2,
        text: "1st Year Price (TLD .orgs)"
      }, {
        index: 3,
        text: "Privacy Protection Fee"
      }, {
        index: 4,
        text: "About"
      }, {
        index: 5,
        text: "Ease of Use"
      }, {
        index: 6,
        text: "Customer Service"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Domain.com",
          subText: "",
          imageKey: "domain-com"
        }
      }, {
        index: 2,
        text: "9.99",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "$14.99"
      }, {
        index: 4,
        text: "$8.99 Per Year"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Started in 2000, Domain.com is one of the most popular domain name registrar on the planet. They allow you to register all top level domain name extensions (TLDs) and even dozens of country code top level domains (ccTLDs)."
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Ease Of Use",
        popOverContents: "The Domain.com checkout process is relatively simple but does feature an obnoxious number of upsells. They also have the base time for a domain purchase set at two years, so you’ll need to adjust it manually if you only want to purchase a domain name for only one year."
      }, {
        index: 7,
        text: "3"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://domain.mno8.net/c/1323808/581160/9560",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Domain.com",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "NameCheap",
          subText: "",
          imageKey: "name-cheap"
        }
      }, {
        index: 2,
        text: "8.88",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "$12.98"
      }, {
        index: 4,
        text: "$12.98 (Per Year)"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Namecheap is fully ICANN-accredited and has grown to become one of the most trusted names in the industry. And Namecheap is also one of the best domain registrars when it comes to balancing price and support."
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Ease Of Use",
        popOverContents: "Buying a domain name on Namecheap is easy. Namecheap’s domain management panel is also simple and intuitive, so they get extra brownie points there."
      }, {
        index: 7,
        text: "4"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.namecheap.com/?sscid=81k4_o116j&utm_source=SAS&utm_medium=Affiliate&utm_campaign=1565605&affnetwork=sas",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Namecheap",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "1and1.com",
          subText: "",
          imageKey: "oneandone"
        }
      }, {
        index: 2,
        text: "1",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "$1"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "1and1.com is yet another reliable domain name company which offers cheap domain names. It is not just a cheap domain name provider but also a cheap hosting provider offering multiple hosting plans for businesses of different levels. "
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Ease Of Use",
        popOverContents: "No Details Available."
      }, {
        index: 7,
        text: "4"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "http://1and1.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to 1and1.com",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Godaddy",
          subText: "",
          imageKey: "godaddy"
        }
      }, {
        index: 2,
        text: "4.99",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "$11.99"
      }, {
        index: 4,
        text: "$7.99 (Per Year)"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "GoDaddy enables customers to select from nearly 400 top-level domains and bolster the domain-driven brand identity with robust hosting and email services."
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Ease Of Use",
        popOverContents: "You might get bombarded with upsells at every turn upon check out."
      }, {
        index: 7,
        text: "3"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "http://www.godaddy.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Godaddy",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Network Solutions",
          subText: "",
          imageKey: "network-solutions"
        }
      }, {
        index: 2,
        text: "9.99",
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Varies"
      }, {
        index: 4,
        text: "$9.99 (Per Year)"
      }, {
        index: 5,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Network Solutions was the original domain registrar and now also offers web hosting services like Cloud and WordPress plans."
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Ease Of Use",
        popOverContents: "No Data Available."
      }, {
        index: 7,
        text: "4.5"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://network-solutions.7eer.net/c/1323808/11435/555",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to Network Solutions",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare web domain management services`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<WebManagementIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="WebManagementIllustration" />, <WebManagementIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="WebManagementIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <DomainComSVG className="w-full" imagekey="domain-com" mdxType="DomainComSVG" />
  <NameCheapSVG className="w-full" imageney="name-cheap" mdxType="NameCheapSVG" />
  <OneAndOneSVG className="w-full" imageney="oneandone" mdxType="OneAndOneSVG" />
  <GoDaddySVG className="w-full" imagekey="godaddy" mdxType="GoDaddySVG" />
  <NetworkSolutionsSVG className="w-full" imagekey="network-solutions" mdxType="NetworkSolutionsSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="business" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="web domain management" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      